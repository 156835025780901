(function () {
    angular.module('informaApp')
        .service('OmnitureDictionaryHelper', OmnitureDictionaryHelper);

    function OmnitureDictionaryHelper() {
        return {
            props: {
                user: {
                    parentServiceAccountName: getProp(13),
                    preferredUsername: getProp(64),
                    userEntitlementType: getProp(20),
                    salesforceServiceId: getProp(40),
                    salesforceId: getProp(41),
                    federatedLocation: getProp(45),
                    federatedDepartment: getProp(46),
                    federatedJobRole: getProp(47),
                    federatedAttribute1: getProp(48),
                    federatedAttribute2: getProp(49)
                },
                filter: {
                    filterName: getProp(32),
                    startDate: getProp(26),
                    endDate: getProp(27),
                    diseases: getProp(51),
                    subDiseases: getProp(52),
                    indications: getProp(53),
                    companies: getProp(54),
                    drugclasses: getProp(55),
                    molecules: getProp(56),
                    targets: getProp(57),
                    drugs: getProp(58),
                    routes: getProp(59),
                    regulatory: getProp(60)
                },
                tableSelectionAction: getProp(33),
                tablePinName: getProp(62),
                exportName: getProp(30),
                shareSearchName: getProp(36),
                headerExitLinkName: getProp(34),
                dashboardTabName: getProp(37),
                exitToBiomedtrackerSourceName: getProp(35)
            },
            events: {
                recordClicks: getEvent(1),
                exports: {
                    downloadDataset: getEvent(5),
                    exportTable: getEvent(38),
                    downloadImage: getEvent(16)
                },
                filter: {
                    resetSection: getEvent(6),
                    generateTable: getEvent(3),
                    filterCount: getEvent(15),
                    saveSelection: getEvent(35),
                    regulatory: getEvent(20)
                },
                tablePin: getEvent(7),
                displayView: getEvent(4),
                account: {
                    myAccount: getEvent(18),
                    login: getEvent(8),
                    logout: getEvent(9),
                    forgottenPassword: getEvent(10)
                },
                phases: {
                    I: getEvent(11),
                    II: getEvent(12),
                    III: getEvent(13),
                    NDABLA: getEvent(14)
                },
                helpCenter: getEvent(17),
                faq: getEvent(19),
                askTheAnalyst: getEvent(41),
                contactClientService: getEvent(17),
                exportCount: getEvent(42),
                shareSearch: getEvent(34),
                headerExitLink: getEvent(40),
                dashboardTab: getEvent(46),
                exitToBiomedtracker: getEvent(39)
            },
            pages: {
                main: 'Pharmapremia',
                login: 'Pharmapremia Login',
                resetPassword: 'Pharmapremia Reset Password',
                FAQ: 'Pharmapremia FAQ',
                MyAccount: 'Pharmapremia My Account',
                chart: 'Pharmapremia Chart',
                ganttChart: 'Pharmapremia Gantt Chart',
                mySearches: 'Pharmapremia My Searches'
            }
        };
    }

    function getProp(num) {
        return 'eVar' + num;
    }

    function getEvent(num) {
        return 'event' + num;
    }
})();
